import config from './config'

/**
 * Check device availability
 * @param requiresCapabilities
 * @param store
 * @param route
 * @returns {Promise<never>|Promise<void>}
 */
function checkDeviceAvailability (requiresCapabilities, store, route) {
  if (requiresCapabilities && !route?.params?.byPass) {
    const terminalModels = route?.params?.definition?.terminalModels
    const item = route?.params?.item
    const deviceAvailability = this.getDeviceAvailability(terminalModels)
    const isSafari = store.getters['useragent/isSafari']
    const isIOS = store.getters['useragent/isIOS']
    const isIpad = store.getters['useragent/isIpad']

    if (!deviceAvailability.includes(config.codes.PC_AVAILABLE)) {
      return Promise.reject({
        id: 'PC_AVAILABLE',
        componentName: 'IncompatiblePc',
        props: {
          item,
          route
        }
      })
      // case 2 -> also need to check if not iOS because you can be isSafari && PC_NOFP on iPhone or iPad (case 3 and 4)
    } else if (isSafari && !isIOS && deviceAvailability.includes(config.codes.PC_NOFP)) {
      return Promise.reject({
        id: 'PC_NOFP',
        componentName: 'CapabilitiesError',
        props: {
          error: {
            title: 'fip.errors.title',
            text1: 'fip.errors.textPcMobileTabletNoFp',
            text2: 'fip.errors.continueOrder',
            appButton: false,
            logError: true,
            stat: 'onHlsfpNotAvailable',
            statArg: 'macos'
          },
          item,
          route
        }
      })
      // case 3
    } else if (isIOS && deviceAvailability.includes(config.codes.MOBILE_NOFP)) {
      return Promise.reject({
        id: 'MOBILE_NOFP',
        componentName: 'CapabilitiesError',
        props: {
          error: {
            title: 'fip.errors.title',
            text1: 'fip.errors.textPcMobileTabletNoFp',
            text2: 'fip.errors.appliTV',
            appButton: true,
            logError: true,
            stat: 'onHlsfpNotAvailable',
            statArg: 'ios'
          },
          item,
          route
        }
      })
      // case 4
    } else if (isIpad && deviceAvailability.includes(config.codes.TABLET_NOFP)) {
      return Promise.reject({
        id: 'TABLET_NOFP',
        componentName: 'CapabilitiesError',
        props: {
          error: {
            title: 'fip.errors.title',
            text1: 'fip.errors.textPcMobileTabletNoFp',
            text2: 'fip.errors.appliTV',
            appButton: true,
            logError: true,
            stat: 'onHlsfpNotAvailable',
            statArg: 'ipad'
          },
          item,
          route
        }
      })
    }
  }

  return Promise.resolve()
}

// --------------------------------------------------------------------------------

function getDeviceAvailability (arrayTerminalModels) {
  const result = []

  if (config.devices.groups.STB.some(item => arrayTerminalModels.includes(item))) {
    result.push(config.codes.TV_AVAILABLE)
  }

  if (config.devices.groups.PC.some(item => arrayTerminalModels.includes(item))) {
    result.push(config.codes.PC_AVAILABLE)
  }

  if ((arrayTerminalModels.includes(config.devices.singles.BROWSER_WIN) ||
  arrayTerminalModels.includes(config.devices.singles.BROWSER_MAC)) &&
  !arrayTerminalModels.includes(config.devices.singles.SAFARI_MAC)) {
    result.push(config.codes.PC_NOFP)
  }

  if (config.devices.groups.TABLET.some(item => arrayTerminalModels.includes(item))) {
    result.push(config.codes.TABLET_AVAILABLE)
  }

  if (arrayTerminalModels.includes(config.devices.singles.TABLET_IOS) &&
  !arrayTerminalModels.includes(config.devices.singles.TABLET_IOS_FP)) {
    result.push(config.codes.TABLET_NOFP)
  }

  if (config.devices.groups.SMARTPHONE.some(item => arrayTerminalModels.includes(item))) {
    result.push(config.codes.MOBILE_AVAILABLE)
  }

  if (arrayTerminalModels.includes(config.devices.singles.SMARTPHONE_IOS) &&
  !arrayTerminalModels.includes(config.devices.singles.SMARTPHONE_IOS_FP)) {
    result.push(config.codes.MOBILE_NOFP)
  }
  return result
}

function checkIsRegisteredAndAvailable (store, arrayTerminalModels, registerTerminal) {
  const deviceAvailability = this.getDeviceAvailability(arrayTerminalModels)
  const isRegistered = store.getters['useragent/isSafari'] && deviceAvailability.includes(config.codes.PC_NOFP) ? false : registerTerminal
  const isPcAvailable = deviceAvailability.includes(config.codes.PC_AVAILABLE)
  return isRegistered && isPcAvailable
}

// --------------------------------------------------------------------------------

export default {
  checkDeviceAvailability,
  getDeviceAvailability,
  checkIsRegisteredAndAvailable
}
