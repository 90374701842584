export default {
  generic: {
    notFound: 'Désolé, la page demandée n\'est plus disponible',
    unknown: 'Une erreur est survenue',
    or: 'ou',
    redirection: 'Vous allez être redirigé(e) vers la page d\'accueil de la video à la demande dans {count} ...',
    unknownMessage: 'Vous pouvez retourner sur la page d\'accueil pour réessayer.',
    link: 'Cliquez ici'
  },
  modals: {
    moreInfo: 'Plus d\'informations',
    genericErrorMessage: 'Nous sommes désolés pour la gêne occasionnée. Si le problème persiste veuillez contacter le support technique.',
    TVM_GENERIC: {
      title: 'Une erreur est survenue ({statusCode}/{errorCode})',
      theme: 'warning'
    },
    TVM_TECHNICAL: {
      title: 'Une erreur est survenue ({statusCode}/{errorCode})',
      text: 'Merci d\'essayer à nouveau. </br>Si le problème persiste, veuillez contacter le support via le lien "Contact" du menu Aide.',
      theme: 'warning'
    },
    TVM_NO_CONTENT: {
      title: 'Erreur',
      text: 'Le contenu ou la catégorie demandé est inaccessible. </br>S\'il s\'agit d\'une vidéo ou d\'un pack que vous avez acheté ou loué, retrouvez-le dans <a href="/mes-videos">Mes vidéos</a>.',
      theme: 'info'
    },
    TVM_EU: {
      title: 'Accès réservé',
      text: 'La TV d\'Orange sur tous les écrans est disponible depuis l\'Union Européenne. </br>La liste des pays ainsi que les restrictions sont disponibles sur <a href="assistance.orange.fr">assistance.orange.fr</a>',
      theme: 'info'
    },
    TVM_NOT_ELIGIBLE: {
      title: 'Accès réservé',
      text: 'Cette fonction est réservée aux clients Orange Internet avec Option TV.',
      theme: 'warning'
    },
    TVM_BOOKMARKS: {
      title: 'Ajout impossible',
      text: 'Cet article ne peut pas être mis en favori.',
      theme: 'info'
    },
    TVM_SIMULTANEOUS_REACH: {
      title: 'Droit de lecture simultanée limité',
      theme: 'info',
      text: {
        SELL: 'Cette vidéo est actuellement visionnée sur vos équipements et le maximum autorisé est atteint. </br>Veuillez d\'abord arrêter un des visionnages avant d\'en lancer un nouveau.',
        RENT: 'Cette vidéo est actuellement visionnée ou téléchargée sur un autre de vos équipements. </br>Pour continuer la lecture, veuillez d\'abord arrêter le visionnage ou supprimer le téléchargement.'
      }
    },
    TVM_MAX_TERMINALS: {
      title: 'Nombre maximum de terminaux atteint',
      text: 'Vous avez atteint le nombre maximum d\'équipements enregistrés. Vous ne pourrez pas regarder votre vidéo sur ce navigateur Internet. </br>Vous pouvez la regarder sur votre décodeur TV d\'Orange ou sur un autre écran enregistré.',
      theme: 'warning'
    },
    CONTENT_PLAYID_NOT_FOUND: {
      title: 'Lecture impossible',
      text: 'Le contenu que vous souhaitez lire n\'est pas disponible. Veuillez réessayer plus tard.',
      theme: 'warning'
    },
    CONTENT_SEASONNUMBER_NOT_FOUND: {
      title: 'Problème lors de la création de la playlist',
      text: 'La saison en cours ne possède pas les données permettant de récupérer les informations de la saison suivante.',
      theme: 'warning'
    },
    confirm: 'ok'
  }
}
