const STB_NBX_HAS = 'STB_NBX_HAS'
const STB_HAS = 'STB_HAS'
const STB_NBX_HAS_PHX = 'STB_NBX_HAS_PHX'
const BROWSER_MAC = 'BROWSER_MAC'
const BROWSER_WIN = 'BROWSER_WIN'
const SAFARI_MAC = 'SAFARI_MAC'
const TABLET_IOS_FP = 'TABLET_IOS_FP'
const TABLET_IOS = 'TABLET_IOS'
const TABLET_ANDROID = 'TABLET_ANDROID'
const TABLET_WIN = 'TABLET_WIN'
const SMARTPHONE_IOS_FP = 'SMARTPHONE_IOS_FP'
const SMARTPHONE_IOS = 'SMARTPHONE_IOS'
const SMARTPHONE_ANDROID = 'SMARTPHONE_ANDROID'
const SMARTPHONE_WIN = 'SMARTPHONE_WIN'
const STB = [STB_NBX_HAS, STB_HAS, STB_NBX_HAS_PHX]
const SMARTPHONE = [SMARTPHONE_IOS_FP, SMARTPHONE_IOS, SMARTPHONE_ANDROID, SMARTPHONE_WIN]
const TABLET = [TABLET_ANDROID, TABLET_IOS, TABLET_IOS_FP, TABLET_WIN]
const PC = [BROWSER_MAC, BROWSER_WIN, SAFARI_MAC]

export default {
  devices: {
    singles: {
      STB_NBX_HAS,
      STB_HAS,
      STB_NBX_HAS_PHX,
      BROWSER_MAC,
      BROWSER_WIN,
      SAFARI_MAC,
      TABLET_IOS_FP,
      TABLET_IOS,
      TABLET_ANDROID,
      TABLET_WIN,
      SMARTPHONE_IOS_FP,
      SMARTPHONE_IOS,
      SMARTPHONE_ANDROID
    },
    groups: {
      SMARTPHONE_WIN,
      STB,
      SMARTPHONE,
      TABLET,
      PC
    }
  },
  codes: {
    TV_AVAILABLE: 'TV_AVAILABLE',
    PC_AVAILABLE: 'PC_AVAILABLE',
    TABLET_AVAILABLE: 'TABLET_AVAILABLE',
    MOBILE_AVAILABLE: 'MOBILE_AVAILABLE',
    CAPACITY_KO: 'CAPACITY_KO',
    USER_NOT_ELIGIBLE: 'USER_NOT_ELIGIBLE',
    USER_NOT_AUTHENTICATED: 'USER_NOT_AUTHENTICATED',
    USER_NOT_UE: 'USER_NOT_UE',
    PC_NOT_AVAILABLE: 'PC_NOT_AVAILABLE',
    PC_NOFP: 'PC_NOFP',
    MOBILE_NOFP: 'MOBILE_NOFP',
    TABLET_NOFP: 'TABLET_NOFP'
  },
  bandwidth: {
    min: 800 // value in Kbps
  }
}
